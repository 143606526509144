import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage} from "gatsby-plugin-image";
import styled from 'styled-components';
import SEO from "../components/SEO";

const HomeContainer = styled.div`
  display: flex;
  height: 100%;

  > div {
    flex: 1 50%;
  }
  
  > div:nth-child(1) {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 7rem;
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    > div:nth-child(1) {
      flex: 1 10%;
      h1 {
        font-size: 4rem;
      }
    }
    > div:nth-child(2) {
      flex: 1 90%;
      img {
        object-position: top;
      }
    }
  }
`

export default function HomePage({ data }) {
  const [ {heading, image} ] = data.sanityPages.pageBuilder.filter(page => page._type === "hero");
  const imageData = getImage(image.asset);

  return ( 
    <HomeContainer>
      <SEO
        title="Home"
        keywords={[`J. Marcelo Borromeo`, `Filipino writer`, `UEA`, `novel`, `short story`, `fiction`, `non-fiction`]}
        />
      <div>
        <h1>{heading}</h1>
      </div>
      <GatsbyImage image={imageData} alt={heading}/>
    </HomeContainer>
  )
}

export const query = graphql`
    query HomePage {
        sanityPages(title: {eq: "About"}) {
            title
            pageBuilder {
                ... on SanityHero {
                    _key
                    _type
                    heading
                    image {
                      asset {
                        id
                        gatsbyImageData
                      }
                    }
                  }
              ... on SanityTextContent {
                _key
                _type
                _rawText
              }
            }
          }
    }
`;